import React, { useLayoutEffect, useState, useRef  } from "react";
import Navbar from "../../Components/Header/Header";
import "./Room.scss";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExpand, faUserGroup, faBed, faChevronRight, faClock, faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import {ENDPOINTS, apiCall} from '../../Lib/App';
import { Link } from 'react-router-dom';
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import HomeImg from "../../Assets/home.jpg"
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const Room = () => {
  const villasSectionRef = useRef(null);
  const [villas, setVillas] = useState([]);
  const location = useLocation();
  const { filteredVillas } = location.state || {};
  const [checkInDate, setCheckInDate] = useState("");
  const [checkOutDate, setCheckOutDate] = useState("");

  useLayoutEffect(() => {
    if (filteredVillas) {
      setVillas(filteredVillas);
    } else {
      fetchVillas();
    }
  }, [filteredVillas]);

  useLayoutEffect(() => {
    if (villas.length > 0 && villasSectionRef.current) {
      villasSectionRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [villas]);



  const fetchVillas = async () => {
    try {
      const endpoint = ENDPOINTS.getAllVillas;
      const response = await apiCall(endpoint);
      console.log(response);
      if (response ) {
        const parsedVillas = response.villas.map(villa => ({
          ...villa,
          // images: JSON.parse(villa.images || "[]")  // Safely parse or default to empty array
        }));
        setVillas(parsedVillas);
      } else {
        setVillas([]);
      }
    } catch (error) {
      console.error("Failed to fetch villas", error);
      setVillas([]);
    }
  }
  const handleCheckInDateChange = (e) => {
    console.log('Check-In Date Changed:', e.target.value);
    setCheckInDate(e.target.value);
  };
  
  const handleCheckOutDateChange = (e) => {
    console.log('Check-Out Date Changed:', e.target.value);
    setCheckOutDate(e.target.value);
  };
  
  const checkAvailability = async () => {
    console.log('Check Availability Triggered');
    console.log('Current Check-In Date:', checkInDate);
    console.log('Current Check-Out Date:', checkOutDate);
  
    if (!checkInDate || !checkOutDate) {
      alert('Please select both check-in and check-out dates.');
      return;
    }
  
    let availableVillas = [];
  
    // Function to normalize dates by removing the time part
    const normalizeDate = (date) => new Date(date.getFullYear(), date.getMonth(), date.getDate());
  
    const normalizedCheckInDate = normalizeDate(checkInDate);
    const normalizedCheckOutDate = normalizeDate(checkOutDate);
  
    console.log('Normalized Check-In Date:', normalizedCheckInDate);
    console.log('Normalized Check-Out Date:', normalizedCheckOutDate);
  
    for (let villa of villas) {
      const endpoint = buildEndpoint(ENDPOINTS.getBookedDatesCheckavailability, villa.villa_id);
      const response = await apiCall(endpoint);
      
      const bookedDates = response.bookedDates;
  
      console.log('Booked Dates:', bookedDates);
  
      // Assume villa is available unless we find a booked date that overlaps
      let isUnavailable = false;
  
      for (let { start_date, end_date } of bookedDates) {
        const startDate = normalizeDate(new Date(start_date));
        const endDate = normalizeDate(new Date(end_date));
        console.log('Checking availability for villa with booked range:', startDate, endDate);
  
        // Check if the check-in or check-out overlaps with a booked date range
        if (
          (normalizedCheckInDate <= endDate && normalizedCheckInDate >= startDate) ||  // check-in overlaps
          (normalizedCheckOutDate <= endDate && normalizedCheckOutDate >= startDate) ||  // check-out overlaps
          (normalizedCheckInDate <= startDate && normalizedCheckOutDate >= endDate)     // booking spans across an entire booked range
        ) {
          console.log(`Villa is unavailable for the range: ${startDate} - ${endDate}`);
          isUnavailable = true;
          break;
        }
      }
  
      console.log('Is villa unavailable:', isUnavailable);
      if (!isUnavailable) {
        availableVillas.push(villa);
      }
    }
  
    if (availableVillas.length === 0) {
      alert('No villas are available for the selected dates.');
    } else {
      setVillas(availableVillas);
    }
  
    if (villasSectionRef.current) {
      villasSectionRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };
  
  
  
  
  const buildEndpoint = (endpoint, id) => {
    const clonedEndpoint = { ...endpoint };
    clonedEndpoint.url += `/${id}`;
    return clonedEndpoint;
  };
  
  const ArrowLeft = (props) => (
    <button {...props} className={"prev-arrow2"}>
      {" "}
      <FontAwesomeIcon icon={faAngleLeft} />
    </button>
  );
  const ArrowRight = (props) => (
    <button {...props} className={"next-arrow2"}>
      {" "}
      <FontAwesomeIcon icon={faAngleRight} />
    </button>
  );

  const roomSettings = {
    dots: true,
    fade: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    waitForAnimate: false,
    autoplay: false,
    prevArrow: <ArrowLeft />,
    nextArrow: <ArrowRight />,
    responsive: [
      {
        breakpoint: 768,
        settings: {
        
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
       
          slidesToShow: 1,
        },
      },
    ],
  };


  const { t, i18n, ready } = useTranslation();
  return (
    <div>
      <Navbar />
      <section className="roomDetails_hero" style={{   backgroundImage: `linear-gradient(to bottom, rgba(0,0,0,0.5), rgba(0,0,0,0.5)), url(${HomeImg})`}}>
        <div className="container">
          <div className="roomDetails_hero_content">
            <p>{t("checkAccomodotion")}</p>
            <h2>{t("rsc")}</h2>
          </div>
        </div>
      </section>
      <section>
        <div className="roomDetails_hero_checkAvaibility">
          <div className="container">
          <div className="home_hero_checkAvaibility-wrapper2">
          <div className="home_hero_checkAvaibility-box">
            <svg xmlns="http://www.w3.org/2000/svg" width="30" viewBox="0 0 30 30">
                  <path d="M7.11.06c-.22.1-.41.31-.49.52-.04.09-.06.52-.06.95v.78l-1.71.02c-1.68.02-1.73.02-2.17.18C1.45 2.95.6 3.79.17 5L0 5.47v21.42l.16.47c.43 1.21 1.27 2.05 2.5 2.48l.45.16h23.77l.47-.16c1.21-.43 2.05-1.27 2.48-2.5l.16-.45V5.47l-.16-.45c-.43-1.23-1.27-2.07-2.48-2.5-.46-.16-.48-.16-2.18-.18l-1.71-.02-.02-.87C23.42.5 23.39.41 23.02.14c-.23-.17-.78-.17-1.01 0-.38.28-.4.36-.42 1.32l-.02.87H8.44l-.02-.87C8.4.51 8.37.42 8.01.15c-.2-.15-.66-.19-.9-.08Zm-.55 4.78c.02.69.07.83.42 1.09.23.17.78.17 1.01 0 .35-.26.4-.39.42-1.09l.02-.64h13.12l.02.64c.02.69.07.83.42 1.09.23.17.78.17 1.01 0 .35-.26.4-.39.42-1.09l.02-.65 1.59.02 1.6.02.33.16c.42.21.76.55.97.97.16.33.16.34.18 1.7l.02 1.37H1.85V7.18c0-.93.02-1.33.08-1.54.18-.59.69-1.13 1.29-1.33.26-.09.52-.1 1.81-.11h1.5l.02.64Zm21.57 13.63-.02 8.18-.16.32c-.21.42-.57.78-.98.99l-.33.16H3.35l-.32-.16c-.42-.21-.78-.57-.99-.98l-.16-.33-.02-8.18V10.3h26.28v8.17Z"></path>
                </svg>
              <div>
                <h5>{t("checkIn")}</h5>
                <DatePicker 
                  selected={checkInDate} 
                  onChange={(date) => setCheckInDate(date)} 
                  placeholderText="Select Check-in Date"
                  dateFormat="dd/MM/yyyy"
                />
              </div>
            </div>
            <div className="home_hero_checkAvaibility-box">
            <svg xmlns="http://www.w3.org/2000/svg" width="30" viewBox="0 0 30 30">
                  <path d="M7.11.06c-.22.1-.41.31-.49.52-.04.09-.06.52-.06.95v.78l-1.71.02c-1.68.02-1.73.02-2.17.18C1.45 2.95.6 3.79.17 5L0 5.47v21.42l.16.47c.43 1.21 1.27 2.05 2.5 2.48l.45.16h23.77l.47-.16c1.21-.43 2.05-1.27 2.48-2.5l.16-.45V5.47l-.16-.45c-.43-1.23-1.27-2.07-2.48-2.5-.46-.16-.48-.16-2.18-.18l-1.71-.02-.02-.87C23.42.5 23.39.41 23.02.14c-.23-.17-.78-.17-1.01 0-.38.28-.4.36-.42 1.32l-.02.87H8.44l-.02-.87C8.4.51 8.37.42 8.01.15c-.2-.15-.66-.19-.9-.08Zm-.55 4.78c.02.69.07.83.42 1.09.23.17.78.17 1.01 0 .35-.26.4-.39.42-1.09l.02-.64h13.12l.02.64c.02.69.07.83.42 1.09.23.17.78.17 1.01 0 .35-.26.4-.39.42-1.09l.02-.65 1.59.02 1.6.02.33.16c.42.21.76.55.97.97.16.33.16.34.18 1.7l.02 1.37H1.85V7.18c0-.93.02-1.33.08-1.54.18-.59.69-1.13 1.29-1.33.26-.09.52-.1 1.81-.11h1.5l.02.64Zm21.57 13.63-.02 8.18-.16.32c-.21.42-.57.78-.98.99l-.33.16H3.35l-.32-.16c-.42-.21-.78-.57-.99-.98l-.16-.33-.02-8.18V10.3h26.28v8.17Z"></path>
                </svg>
              <div>
                <h5>{t("checkOut")}</h5>
                <DatePicker 
                  selected={checkOutDate} 
                  onChange={(date) => setCheckOutDate(date)} 
                  placeholderText="Select Check-out Date"
                  dateFormat="dd/MM/yyyy"
                />
              </div>
            </div>
            <div className="home_hero_checkAvaibility-box">
              <a className="main-btn" onClick={checkAvailability}>{t("checkAvailability")}</a>
            </div>
            </div>
          </div>
        </div>
      </section>
      <section className="roomDetails_welcome">
        <div className="container">
          <div className="roomDetails_welcome-wrapper">
            <span>{t("welcome")}</span>
            <h2>
            {t("roomExperience")}
            </h2>
            <p>
            {t("roomExperienceText")}

            </p>
            <div className="roomDetails_welcome_actions">
              <a className="border-btn border-link" href="#section1">
              {t("mainServices")}
              </a>
              <a className="border-btn border-link" href="#section2">
              {t("roomsViews")}
              </a>

            </div>
          </div>
        </div>
      </section>
      <section id="section1" className="roomDetails_banner" style={{ backgroundImage: "linear-gradient(to bottom, rgba(0,0,0,0.5), rgba(0,0,0,0.5)),url(https://cozystay.loftocean.com/mountain-chalet/wp-content/uploads/sites/4/2023/05/clay-banks-zqMSQ8NtBvM-unsplash.jpg)" }}>
        <div className="container">
          <div className="roomDetails_banner-container">
            <span>{t("welcome")}</span>
            <h2>{t("ecl")}</h2>
            <p>{t("roomsOffering")}</p>
          </div>
        </div>
      </section>
      <section className="roomDetails_roomSlide">
        <div className="roomDetails_roomSlide_icon">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 700 10" preserveAspectRatio="none">
            <path className="elementor-shape-fill" d="M350,10L340,0h20L350,10z"></path>
          </svg>
        </div>
        <div className="container">
        <div ref={villasSectionRef} className="roomDetails_roomSlide-container">
        {villas.map((room, roomIndex) => {
  const language = i18n.language; // Get the current language
  const description = room.description[language] || room.description['en']; // Fallback to English if the translation is missing
  const name = room.name[language] || room.name['en'];

  const truncatedDescription = description.length > 120
    ? `${description.substring(0, 120)}...`
    : description;

  return (
    <Link to={`/roomDetails/${room.villa_id}`} state={{ villaData: room }} key={roomIndex} className="room_villa_box">

      <Slider {...roomSettings}>
        {room.images.map((image, imageIndex) => (
          <div key={imageIndex} className="room_villa_img">
            <img src={image} alt={`Slide ${imageIndex + 1}`} />
          </div>
        ))}
      </Slider>
      <div className="roomDetails_similar_price">€{room.price_per_night}</div>
      <div className="room_villa_content">
        <h2>{name}</h2>
        <div className="room_villa_info-top">
          <span>{room.area_size}</span> / Great View / <span>{room.max_guests}</span>
        </div>
        <div className="room_villa_info">
          <div className="room_villa_info-item">
            <FontAwesomeIcon icon={faExpand} />
            <span>{room.area_size}</span>
          </div>
          <div className="room_villa_info-item">
            <FontAwesomeIcon icon={faUserGroup} />
            <span>{room.max_guests}</span>
          </div>
          <div className="room_villa_info-item">
            <FontAwesomeIcon icon={faBed} />
            <span>{room.number_of_beds}</span>
          </div>
        </div>
        <p>
          {truncatedDescription}
        </p>
        <a href="#">
        {t("discoverMore")} <FontAwesomeIcon icon={faChevronRight} />
        </a>
      </div>
    </Link>
  );
})}

        </div>
        </div>
      </section>
      <section id="section2" className="roomDetails_banner" style={{ backgroundImage: "linear-gradient(to bottom, rgba(0,0,0,0.5), rgba(0,0,0,0.5)),url(https://cozystay.loftocean.com/mountain-chalet/wp-content/uploads/sites/4/2023/05/clay-banks-zqMSQ8NtBvM-unsplash.jpg)" }}>
        <div className="container">
          <div className="roomDetails_banner-container">
          <span>{t("welcome")}</span>
          <h2>{t("ecl")}</h2>
             <p>{t("roomsOffering")}</p>
          </div>
        </div>
      </section>
      <section className="roomDetails_similar">
        <div className="container">
          <div className="roomDetails_similar-content">
            <div className="roomDetails_similar-lists">
              {villas.slice(-3).map((room, roomIndex) => {
                 const language = i18n.language; 
                 const description = room.description[language] || room.description['en']; // Fallback to English if the translation is missing
                 const name = room.name[language] || room.name['en'];
                 const truncatedDescription = description.length > 120
                   ? `${description.substring(0, 120)}...`
                   : description;
                return (
                <div className="item-card" key={roomIndex}>
                <Link to={`/roomDetails/${room.villa_id}`} state={{ villaData: room }}>

                    <div className="roomDetails_similar_price">€{room.price_per_night} / Night</div>
                    <div
                      className="item-card-hover"
                      style={{
                        // backgroundImage: `linear-gradient(to bottom, rgba(0,0,0,0.2), rgba(0,0,0,0.2)), url(${room.images[0]})`,
                        backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), url('${room.images[0]}')`,
                        backgroundSize: 'cover',
                      }}
                    >
                      <div className="card-image">
                        <div className="hover-content">
                          <div className="hover-text">
                            <p>€{room.price_per_night} / NIGHT</p>
                            <p>{truncatedDescription> 120 ? `${truncatedDescription.substring(0, 120)}...` : truncatedDescription}</p>
                            <a href="#" className="discover-more">
                            {t("discoverMore")} <FontAwesomeIcon icon={faChevronRight} />
                            </a>
                          </div>
                        </div>
                        <div className="card-details">
                          <h2>{name}</h2>
                          <p>{room.area_size}m² / Mountain View / {room.max_guests} Guests</p>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
                )
 })}
            </div>
          </div>
        </div>
      </section>
      <section className="roomDetails_bannerBottom" style={{ backgroundImage: "linear-gradient(to bottom, rgba(0,0,0,0.5), rgba(0,0,0,0.5)),url(https://cozystay.loftocean.com/mountain-chalet/wp-content/uploads/sites/4/2023/05/clay-banks-zqMSQ8NtBvM-unsplash.jpg)" }}>
        <div className="container">
          <div className="roomDetails_bannerBottom-container">
            <span>{t("welcome")}</span>
            <h2>
            {t("roomsComfortable")}            </h2>
            <p>{t("roomsComfortableText")}</p>
            <div className="roomDetails-checkIn">
              <div>
                <FontAwesomeIcon icon={faClock} />
                <span>  {t("checkInTime")}</span> <span> 14:00 </span>
              </div>
              <div>
                <FontAwesomeIcon icon={faClock} />
                <span>  {t("checkOutTime")}</span> <span>  11:00</span>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Room;