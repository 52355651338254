import axios from "axios";

// Base URL configuration for axios
const API_BASE_URL = "https://bujtinatlisa.com/api";

export const apiCall = async (endpoint, options = null) => {
  let data = {};
  if (options) {
    data = options.data;
  }

  try {
    const response = await axios({
      method: endpoint.method,
      url: `${API_BASE_URL}${endpoint.url}`,
      data,
      withCredentials: false
    });
    return response.data;
  } catch (error) {
    console.error("API call error:", error);
    return false;
  }
};

export const ENDPOINTS = {
  getAllVillas: { method: "GET", url: "/villas" },
  getVilla: { method: "GET", url: "/villas/{id}" },
  addBooking: {method: "POST", url: "/addBooking"},
  getBookedDates: {method: "GET", url: "/villas/booked-dates/{id}"},
  getBookedDatesCheckavailability: {method: "GET", url: "/villas/booked-dates"},
  editBooking: { method: "PUT", url: "/villas/edit/{id}" },
  deleteBooking: { method: "DELETE", url: "/villas/delete/{id}" },
};
